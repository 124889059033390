<template>
	<div class="my_index">
		<div class="my_index_top">
			<div class="top_user">
					<div class="top_user_title">
						MY DASHBOARD
						<router-link to="/my_msg"><img src="../../assets/img/bi.png" class="bi" alt=""></router-link>
					</div>
				
				
				<div class="top_user_geng">
					<div class="geng_text">
						<!-- 2022/8/24 更新 -->
					</div>
					<div class="xian">

					</div>
				</div>

				<div class="top_user_one">
					<div class="one_left">
						<!-- <img src="../../assets/img/my_index_bg.png" class="my_bg" alt=""> -->
						<div class="enname">{{userInfo.en_name||''}}</div>
						<div class="left_user">
							
								<div class="user_name">
									<div class="name_title">
										{{userInfo.open_name}}
									</div>
									<div class="name_msg">
										{{userInfo.profession}}
									</div>
									<div class="name_msg">
										{{userInfo.province}} / {{userInfo.city}}
									</div>
								</div>
							
							<div class="left_num">
								
								<div class="num_title">
									<el-select :class="{0:'green',1:'yellow',2:'red'}[userInfo.working_status]"  v-model="userInfo.working_status" @change="changeWorkingStatus"  :placeholder="$t('QXZ')">
										<el-option v-for="item in workingStatus" :key="item.data_code" :label="item.data_value"
											:value="item.data_code/1">
										</el-option>
									</el-select>
									<!-- <el-button type="primary" style="background: #B65664;color: #fff;border: none;">{{$util.getUnitVal(workingStatus,userInfo.working_status)}}</el-button> -->
									<div class="video_list">
										<img src="../../assets/img/icon_tt.png" @click="share2(userInfo.user_id,'twitter',userInfo.open_name)" class="video2" alt="" style="cursor: pointer;">
										<img src="../../assets/img/icon_in.png" @click="share2(userInfo.user_id,'Linkedin',userInfo.open_name)" class="video2" alt="" style="cursor: pointer;">
										<img src="../../assets/img/icon_fb.png" @click="share2(userInfo.user_id,'facebook',userInfo.open_name)" class="video3" alt="" style="cursor: pointer;">
										<!-- <a :href="userInfo.twitter_url" v-if="userInfo.twitter_url">
											<img src="../../assets/img/icon_tt.png" class="video2" alt="">
										</a>
										<a :href="userInfo.Linkedin_url" target="_blank" v-if="userInfo.Linkedin_url"><img src="../../assets/img/icon_in.png" class="video2" alt=""></a>
										<a :href="userInfo.facebook_url" target="_blank" v-if="userInfo.facebook_url"><img src="../../assets/img/icon_fb.png" class="video3" alt=""></a> -->
									</div>
								</div>
								<div class="num_box">
									<div class="box">
										<div>
											<!-- 投稿数 -->
											{{$t('TGS')}}
										</div>
										<div>{{userInfo.recCount}}</div>
									</div>
									<router-link to="my_guanz?showIndex=2">
										<div class="box">
											<div>
												<!-- 粉丝 -->
												{{$t('FANS')}}
											</div>
											<div>{{userInfo.fans}}</div>
										</div>
									</router-link>
									<router-link to="my_guanz">
										<div class="box">
											<div>
												<!-- 关注 -->
												{{$t('FOLLOW')}}
											</div>
											<div>{{userInfo.follows}}</div>
										</div>
									</router-link>
									<div class="box">
										<div>
											<!-- 评论 -->
											{{ $t("task.PL") }}
										</div>
										<div>{{userInfo.commentCount}}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="left_center_box">
							<img :src="userInfo.avatar_url" class="center_img" alt="">
							<div class="center_about">
								<div class="about_title">
									ABOUT
									<router-link to="/my_msg?showIndex=1&targetid=grjj">
										<img src="../../assets/img/bi.png" class="bi" alt="">
									</router-link>
								</div>
								<div class="about_msg">
									{{userInfo.description}}
								</div>
								<div class="about_title">
									<!-- 工作经历 -->
									{{ $t("my_msg.GZJL") }}
									<router-link to="/my_msg?showIndex=2&targetid=gzjl">
										<img src="../../assets/img/bi.png" class="bi" alt="">
									</router-link>
								</div>
								<div class="about_li" v-for="item in userInfo.job_exp" :key="item">
									<img src="../../assets/img/job_img.png" class="job" alt="">
									<div class="li_name">
										<div class="msg">
											{{item.name}}({{item.start_time}} 〜 {{item.end_time}})
										</div>
										<div class="msg2">
											{{item.content}}
										</div>
									</div>
								</div>
								
								<div class="about_title">
									<!-- 学歴 -->
									{{$t('XUELI')}}
									<router-link to="/my_msg?showIndex=2&targetid=xxjl">
										<img src="../../assets/img/bi.png" class="bi" alt="">
									</router-link>
								</div>
								<div class="about_li" v-for="item in userInfo.study_exp" :key="item">
									<img src="../../assets/img/job_img.png" class="job" alt="">
									<div class="li_name">
										<div class="msg">
											{{item.name}}({{item.start_time}} 〜 {{item.end_time}})
										</div>
										<div class="msg2">
											{{item.experience}}
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
					</div>
					<div class="one_right">
						<div class="right_num">
							<div class="num_box">
								<div class="box_top">
									<img src="../../assets/img/index_money.png" class="top_money" alt="">
									<div class="top_name">
										<!-- 希望时薪 -->
										{{ $t("my_msg.XWXZ") }} 
									</div>
								</div>
								<div class="box_xian">
									
								</div>
								<div class="box_bottom">
									{{userInfo.hope_wage||'-'}}<span style="font-size: 12px;margin-left: 5px;">{{userInfo.salary_unit}}</span>
								</div>
							</div>
							<div class="num_box">
								<div class="box_top">
									<img src="../../assets/img/index_money2.png" class="top_money" alt="">
									<div class="top_name" style="text-align: center;">
										
										<!-- 希望劳动时间 -->{{$t('HOPETIME')}}
										<!-- <div style="margin-top: 3px;">{{$t('MEITIAN')}}</div> -->
										<!-- <br><template v-if="$util.getUnitVal(jobTime,userInfo.job_time_method)">({{$t('EVERY')}}{{$util.getUnitVal(jobTime,userInfo.job_time_method)}})</template> -->
									</div>
								</div>
								<div class="box_xian">
									
								</div>
								<div class="box_bottom" v-if="userInfo.job_time_val">
									{{userInfo.job_time_val}}
									<!-- 小时 -->
									<span style="font-size: 12px;margin-left: 5px;">{{ $t("my_msg.XS") }}</span>
								</div>
								<div class="box_bottom" v-else>-</div>
							</div>
							<div class="num_box">
								<div class="box_top">
									<img src="../../assets/img/index_money3.png" class="top_money" alt="">
									<div class="top_name">
										<!-- 希望开始时间 -->
										{{$t('HOPESTART')}}
									</div>
								</div>
								<div class="box_xian">
									
								</div>
								<div class="box_bottom"  style="font-size:22px;white-space: normal;" v-if="userInfo.job_time">
									{{userInfo.job_time}}
								</div>
								<div class="box_bottom"  v-else>
									-
								</div>
							</div>
							<div class="num_box">
								<div class="box_top" style="flex-direction: initial;">
									<img src="../../assets/img/index_money4.png" class="top_money" style="margin-right: 10px;" alt="">
									<img src="../../assets/img/index_money5.png" class="top_money" alt="">
								</div>
								<!-- <div class="box_xian">
									
								</div> -->
								<div class="box_bottom" v-if="userInfo.job_type" style="display: block; font-size:22px;line-height: 1.3;">
									
									<div v-for="item in userInfo.job_type" :key="item">{{item}}</div>
									
								</div>
								<div class="box_bottom" v-else>-</div>
							</div>
						</div>
						<div class="right_echarts">
							<div class="echarts_one">
								<div class="one_title">
									<!-- 接包 -->
									{{ $t("task.JB") }}
								</div>
								<div class="one_pin">
									<div class="pin_box">
										<div class="box_num">
											<!-- 订单评估 -->
											{{ $t("task.DDPG") }}
											<span>{{recInfo.recScore}}</span>
										</div>
										<div class="box_star">
											<span style="margin-right: 10px;">({{recInfo.recCompleteCount}}<!-- 件中 -->{{$t('JIANZHONG')}})</span>
											<el-rate void-color="#666" disabled-void-color="#666" :value="recInfo.recScore" disabled></el-rate>
										</div>
									</div>
									<div class="pin_xian">
										
									</div>
									<div class="pin_box">
										<div class="box_num">
											<!-- 实际订单 -->{{$t('REALORDER2')}}
											<span style="margin-right: 5px;">{{recInfo.recRealCount}}</span>{{$t('JIAN')}}
										</div>
										<!-- 累计金额 -->
										<!-- <div class="box_star">
											{{$t('ALLAMOUNT')}}：¥{{recInfo.recSalary}}
										</div> -->
									</div>
								</div>
							</div>
							<div class="echarts_box" id="echarts">
								
							</div>
						</div>
						
						<div class="right_echarts">
							<div class="echarts_one">
								<div class="one_title">
									<!-- 发包 -->
									{{ $t("task.FB") }}
								</div>
								<div class="one_pin">
									<div class="pin_box">
										<div class="box_num">
											<!-- 订单评估 -->
											{{ $t("task.CGDDPG") }}
											<span>{{sendInfo.sendScore}}</span>
										</div>
										<div class="box_star">
											
											<span style="margin-right: 10px;">({{sendInfo.sendCompleteCount}}<!-- 件中 -->{{$t('JIANZHONG')}})</span>
											<el-rate void-color="#666" disabled-void-color="#666" :value="sendInfo.sendScore" disabled></el-rate>
										</div>
									</div>
									<div class="pin_xian">
										
									</div>
									<div class="pin_box">
										<div class="box_num">
											<!-- 实际订单 -->{{$t('REALORDER')}}
											
											<span style="margin-right: 5px;">{{sendInfo.sendRealCount}}</span>{{$t('JIAN')}}
										</div>
										<!-- 累计金额 -->
										<!-- <div class="box_star">
											{{$t('ALLAMOUNT')}}：¥{{sendInfo.sendSalary}}
										</div> -->
									</div>
								</div>
							</div>
							<div class="echarts_box" id="echarts2">
								
							</div>
						</div>
						
						
						
					</div>
				</div>
			</div>
			<Homeright></Homeright>
		</div>
		
		<div class="my_index_two" >
			<!-- 左 -->
			<div class="two_left">
				<div class="left_title">
					<!-- 技能评估 -->{{$t('task.JNPG')}}
				</div>
				<div class="left_pro">
					<div class="pro_list" v-for="item in skillInfo" :key="item.cust_skill_id">
						<div class="list_name">
							{{item.skill_name}}
						</div>
						
						<div class="list_btn" @click="zanSkill(item.cust_skill_id)" style="cursor: pointer;">
							<img src="../../assets/img/home_zan2.png" class="btn" alt="" v-if="item.is_favor==0">
							<img src="../../assets/img/home_zan1.png" class="btn" alt="" v-else>
							{{item.favor_count}}<!-- 人 -->{{$t('REN')}}
						</div>
					</div>
					
				</div>
				<div class="left_title">
					<!-- 实际评估 -->{{$t('task.SJPG')}}
				</div>
				
				<div class="table_title">
					<div class="" :class="pgtype==1?'active':'title'" @click="pgtype=1" style="border-radius: 30px 0 0 0;cursor: pointer;">
						<!-- 订单评估 -->{{$t('task.DDPG')}}
					</div>
					<div  :class="pgtype==2?'active':'title'" @click="pgtype=2" style="border-radius: 0 30px 0 0;cursor: pointer;">
						<!-- 采购订单评估 -->{{$t('task.CGDDPG')}}
					</div>
				</div>
				<div class="left_table" :class="{two:pgtype==2}">
					
					<div class="table_header">
						<div class="header">
							<!-- 日期 -->{{$t('task.RQ')}}
						</div>
						<div class="header">
							<!-- 评审员 -->{{$t('task.PSY')}}
						</div>
						<div class="header">
							<!-- 评分 -->{{$t('task.PF')}}
						</div>
						<div class="header">
							<!-- 项目类型 -->{{$t('task.XMLX')}}
						</div>
						<div class="header_last">
							<!-- 评论 -->{{$t('task.PL2')}}
						</div>
					</div>
					<template v-if="pgtype==1">
						<div class="table_body" v-for="item in ajorder" :key="item.job_comment_id">
							<div class="body">
								{{item.employee_comment_time.split(' ')[0]}}
							</div>
							<div class="body">
								<img :src="item.employer_avatar_url" class="body_img" alt="">
							</div>
							<div class="body">
								{{item.score}}<!-- {{$t('FEN')}} -->
								<el-rate void-color="#666" disabled-void-color="#666" v-model="item.score" disabled></el-rate>
							</div>
							<div class="body">
								{{item.cate_first_name}}/{{item.cate_sec_name}}/{{item.cate_third_name}}
							</div>
							<div class="body_last">{{item.employee_content}}</div>
						</div>
					</template>
					<template v-if="pgtype==2">
						<div class="table_body" v-for="item in cgorder" :key="item.job_comment_id">
							<div class="body">
								{{item.employer_comment_time}}
							</div>
							<div class="body">
								<img :src="item.employee_avatar_url" class="body_img" alt="">
							</div>
							<div class="body">
								{{item.score}}<!-- {{$t('FEN')}} -->
								<el-rate void-color="#666" disabled-void-color="#666" v-model="item.score" disabled></el-rate>
							</div>
							<div class="body">
								{{item.cate_first_name}}/{{item.cate_sec_name}}/{{item.cate_third_name}}
							</div>
							<div class="body_last">{{item.employer_content}}</div>
						</div>
						
					</template>
					
				</div>
				
				<template v-if="liuyan_list.length>0">
				<div class="left_title">
					<!-- 推荐详情信息 -->{{$t('TJXXXQ')}}
				</div>
				<div class="left_table" style="border-radius: 45px;padding:30px;box-sizing: border-box;" >
					<div style="background-color: #333;padding:20px;border-radius: 20px;">
						<div class="liuyan_list">
							<div class="item" v-for="item in liuyan_list" :key="item.guest_id">
								
								<div class="flex-start">
									<div class="touxiang"><img :src="item.guest_avatar_url"></div>
									<div class="flex-item">
										<div>{{item.guest_open_name}}</div>
										<div class="date">{{$util.rTime(item.create_time)}}</div>
										<div class="cont" v-html="item.content"></div>
									</div>
									<div>
										<el-button type="primary" size="mini" @click="del_liuyan(item.guest_id)" style="background-color: #567BB6;border:none;"><!-- 删除 -->{{$t('activity.SC')}}</el-button>
									</div>
								</div>
								
							</div>
						</div>
						<!-- 查看全部 -->
						<div class="pin_more" v-if="liuyan_isLast==0&&liuyan_list.length>0" @click="getMsgList" style="text-align: center;font-size: 12px;color:#567BB6;cursor: pointer;padding:10px 0;">
							{{$t('CKGD')}}
						</div>
						
					</div>
				</div>
				</template>
			</div>
			<!-- 右 -->
			<div class="two_right">
				<div class="right_user">
					<div class="home_list" v-for="(item,index) in dongtai" :key="item.trend_id" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
						<div @click="todetail(item.trend_id)">
						<div class="list_title">
						
							<img :src="item.avatar_url"  style="cursor: pointer;" class="title_img" alt="">
						
							<div class="list_user">
								<div class="user">
									{{item.open_name}} / {{item.profession}}
								</div>
								<div class="user_msg">
									{{item.create_time}}
								</div>
							</div>
							<div class="flex-item"></div>
							
							<!-- <el-button class="active" size="small" @click.stop="guanzhu(item.user_id,item.user_role,index)" v-if="item.is_fans==1">{{$t('home.YGZ')}}</el-button>
							<el-button type="primary"  size="small" @click.stop="guanzhu(item.user_id,item.user_role,index)" v-else>{{$t('FOLLOW')}}</el-button> -->
							
						</div>
						<div class="list_text">
							{{item.content}}
						</div>
						
						
						<div class="img_list" v-if="item.media_files" >
							<!-- <img :src="img" class="img" alt="" v-for="img in item.media_files" :key="img"> -->
							<el-image :src="img" class="img" v-for="img in item.media_files" :key="img"
							   fit="contain" :preview-src-list="item.media_files" @click.stop="">
							  </el-image>
						</div>
						
						<div class="like_box">
							<img src="../../assets/img/like_fill.png" class="like_img" alt="">
							{{item.favor_desc}}
						</div>
						</div>
						<div class="btn_box">
							<div class="btn_left">
								<div class="left_menu"  style="cursor: pointer;" @click.stop="zan(item.trend_id,index)">
									
									<img src="../../assets/img/home_zan1.png" class="btn1" alt="" v-if="item.is_favor==1" >
									<img src="../../assets/img/home_zan2.png" class="btn1" alt="" v-else>
									{{item.favor_count}}
								</div>
								<div class="left_menu"  style="cursor: pointer;" @click.stop="toggleComment(item.trend_id)">
									<img src="../../assets/img/home_btn5.png" class="btn2" alt="">
									{{item.comment_count}}
								</div>
								<div class="left_menu">
									<img src="../../assets/img/home_btn6.png" class="btn3" alt="">
									{{item.share_count}}
									<div class="sharemenu">
										<div @click="share(item.trend_id,'facebook',item.content)">Facebook</div>
										<div @click="share(item.trend_id,'Linkedin',item.content)">LinkedIn</div>
										<div @click="share(item.trend_id,'twitter',item.content)">Twitter</div>
									</div>
								</div>
							</div>
							<router-link :to='"/home?trend_id="+item.trend_id'>
								<img src="../../assets/img/more2.png" class="right_btn" alt="">
							</router-link>
								
								
							
						</div>
						<template v-if="trend_id==item.trend_id">
							<!-- 评论 -->
							<el-input class="input_box" id="plbox" :placeholder="$t('QSR')" v-model="comment_content"></el-input>
							<div class="input_add">
								<!-- <img src="../../assets/img/biaoqin.png" class="biaoqin" alt=""> -->
								<biaoqing inputId="plbox" ref="biaoqing" white></biaoqing>
								<el-button type="primary" size="mini" @click="sendComment"><!-- 评论 -->{{$t('task.PL3')}}</el-button>
							</div>
							<!-- 评论列表 -->
							<div class="pin_list">
								<div class="list_box" v-for="(comment,cindex) in comment_list" :key="comment.trend_comment_id">
									
										<img :src="comment.avatar_url"  style="cursor: pointer;" @click.stop="toUserPage(comment.user_id,comment.user_role)" class="box_img" alt="">
									
									
									<div class="box_right">
										<div class="right_user">
											<div @click.stop="toUserPage(comment.user_id,comment.user_role)" class="user_name">
												<div class="name">
													{{comment.open_name}}/ {{comment.profession}}<template v-if="comment.reply_comment_id"> &gt; {{comment.reply_open_name}}</template>
											
												</div>
												<div class="name_time">
													{{$util.rTime(comment.create_time)}}
												</div>
											</div>
											<div class="flex-item"></div>
											<div class="user_btn">									
												<img src="../../assets/img/home_zan1.png" class="btn1" style="cursor: pointer;" v-if="comment.is_favor==1" alt="" @click="zanComment(comment.trend_comment_id,cindex)">
												<img src="../../assets/img/home_zan2.png" class="btn1" style="cursor: pointer;" alt="" v-else @click="zanComment(comment.trend_comment_id,cindex)">
												<img src="../../assets/img/home_btn5.png" class="btn2" style="cursor: pointer;" alt="" @click="openhuifu(comment.trend_comment_id,comment.open_name)">
												<!-- <img src="../../assets/img/home_btn4.png" class="btn3" alt=""> -->
											</div>
										</div>
										<div class="right_msg" v-html="comment.content">
											
										</div>
										
									</div>
								</div>
								
							</div>
							<!-- 查看全部 -->
							<div class="pin_more">
								<el-button @click="getCommentList">{{comment_isLast==0?$t('CKGD'):''}}</el-button>
							</div>
						</template>
					</div>
					
				</div>
				<div class="right_last">
					<div class="left_title">
						<!-- 产品组合 -->{{$t('task.CPZH')}}
					</div>
					<div class="last_box" v-if="userInfo.portfolio.length>0">
						<div class="box" v-for="item in userInfo.portfolio" :key="item">
							<!-- <img :src="item" class="box_img" alt=""> -->
							<el-image :src="item" class="box_img"
							   fit="contain" :preview-src-list="userInfo.portfolio" @click.stop="">
							  </el-image>
							<!-- <div class="box_name">
								NIKE 
							</div>
							<div class="box_msg">
								平面设计
							</div> -->
						</div>
						
						
					</div>
					<div class="last_box" style="align-items: center;justify-content: center;" v-else>
						<router-link to="/my_msg?targetid=bcxx">
						<el-button type="primary" style="background: #567BB6;border:none">{{$t('SCZPJ')}}</el-button>
						</router-link>
					</div>
				</div>
			</div>
		
		</div>
		<!-- <Mystudy></Mystudy>
		<Myproject></Myproject>
		<Myjob></Myjob> -->
		<Reply ref="huifu" :id="reply_comment_id" :trend_id="trend_id" :name="reply_comment_name" @send="handleSend"></Reply>
	</div>
</template>

<script>
	import Mystudy from '../../components/my/my_study.vue';
	import Myproject from '../../components/my/my_project.vue';
	import Myjob from '../../components/my/my_job.vue';
	import Homeright from '../../components/home/home_right.vue';
	import Reply from '../../components/home/reply.vue';
	import biaoqing from '../../components/biaoqing.vue';
	export default {
		components: {
			Homeright,
			Mystudy,
			Myproject,
			Myjob,
			Reply,
			biaoqing
		},
		data() {
			return {
				user_id:'',
				userInfo:{},
				workingStatus:[],
				jobInfo:{},
				
				show: false,
				jobTime:[],
				sendInfo:{},
				recInfo:{},
				
				
				skillInfo:[],				
				cgorder:[],
				ajorder:[],
				pgtype:1,
				
				dongtai:[],
				trend_id:'',
				comment_pageSize:15,
				comment_currentPage:1,
				comment_isLast:0,
				comment_list:[],
				comment_content:[],
				
				reply_comment_id:0,
				reply_comment_name:'',
				comment_content2:'',
				
				tjuser:[],
				tjqy:[],
				tjindex:0,
				
				liuyan_pageSize:15,
				liuyan_currentPage:1,
				liuyan_isLast:0,
				liuyan_list:[],
			};
		},
		async created() {
			this.user_id=this.$local.get('user_id');
			await this.getDatas();
			//this.getJobInfo();
			this.getSkillInfo();
			this.getPJ();
			this.getDT();
			this.getTJ();
			this.getMsgList();
			this.getUnit();
		},
		mounted() {
			
		},
		methods:{
			//留言
			async del_liuyan(id){
				this.$confirm(this.$t('QDSCM')+'?', this.$t('TISHI'), {
				  confirmButtonText: this.$t('CONFIRM'),
				  cancelButtonText: this.$t('CANCEL'),
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/guestbook/delete',{guest_id:id});
				  this.$message({
					type: 'success',
					message: res.message
				  });
					this.getMsgList('reload');
				}).catch(() => {
				           
				});
			},
			//获取留言列表
			async getMsgList(t){
				if(t=='reload'){
					this.liuyan_currentPage=1;
					this.liuyan_isLast=0;					
				}
				if(this.liuyan_isLast==1){
					return;
				}
				let res=await this.$request.post('/api/guestbook/page',{user_id:this.user_id,user_role:0,currentPage:this.liuyan_currentPage,pageSize:this.liuyan_pageSize});
				
				
				
				if(t=='reload'){
					this.liuyan_list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.liuyan_list=[...this.liuyan_list,...res.data.records];
					}						
				}
				if(this.liuyan_currentPage>=res.data.totalPage){
					this.liuyan_isLast=1;
				}else{
					this.liuyan_currentPage+=1;
				}
				
			},
			share2:function(id,type,title){
				var href=this.$config.website+'people_page?user_id='+id;				
				this.$util.share(href,type,title);
			},
			share:function(id,type,title){
				var href=this.$config.website+'home?trend_id='+id;
				this.$request.get('/api/trend/share',{trend_id:id});
				this.$util.share(href,type,title);
			},
			async getTJ(){
				let res=await this.$request.get('/api/custuser/recommendCust');
				this.tjuser=res.data.records;
				let res2=await this.$request.get('/api/bususer/recommendBus');
				this.tjqy=res2.data.records;
			},
			//动态详情
			todetail(id){
				this.$router.push({path:'/home',query:{trend_id:id}});
			},
			//关注用户
			async guanzhu(id,role,index){
				let res=await this.$request.get('/api/friends/follow',{user_id:id,user_role:role});
				this.dongtai[index].is_fans=this.dongtai[index].is_fans==1?0:1;
				if(this.dongtai[index].is_fans==0){
					this.$util.guanzhu(id);
					this.dongtai[index].is_fans=1;
				}else{
					this.$util.quguan(id);
					this.dongtai[index].is_fans=0;
				}
			},
			//回复评论成功
			handleSend(){
				this.getCommentList('reload');
			},
			//打开回复评论弹框
			openhuifu(id,name){				
				this.reply_comment_id=id;
				this.reply_comment_name=name;
				this.$refs.huifu.open();
			},
			//点赞评论
			async zanComment(id,index){
				let res=await this.$request.get('/api/trend/favorComment',{trend_comment_id :id});				
				this.comment_list[index].is_favor=this.comment_list[index].is_favor==1?0:1;				
			},
			//点赞动态
			async zan(id,index){
				
				let res=await this.$request.get('/api/trend/favor',{trend_id:id});
				// this.dongtai[index].is_favor=this.dongtai[index].is_favor==1?0:1;
				// let n=this.list[index].is_favor==1?1:-1;
				// this.dongtai[index].favor_count+=n;
				this.getDT();
				
				// this.$message({
				// 	message:res.message,
				// 	type:'success'
				// });				
			},
			//评论动态
			async sendComment(){
				let val=this.$refs.biaoqing[0].getval();
				console.log(val);
				let res=await this.$request.post('/api/trend/comment',{trend_id:this.trend_id,content:val});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.comment_content='';				
				this.getCommentList('reload');
			},
			//展开收起评论
			toggleComment(trend_id){
				console.log(11);
				if(this.trend_id==trend_id){
					this.trend_id=0;								
				}else{
					this.comment_content='';
					this.trend_id=trend_id;					
					this.getCommentList('reload');
				}
			},
			//获取动态评论列表
			async getCommentList(t){
				if(t=='reload'){
					this.comment_currentPage=1;
					this.comment_isLast=0;					
				}
				if(this.comment_isLast==1){
					return;
				}
				let res=await this.$request.post('/api/trend/commentPage',{trend_id:this.trend_id,currentPage:this.comment_currentPage,pageSize:this.comment_pageSize});
				
				
				
				if(t=='reload'){
					this.comment_list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.comment_list=[...this.comment_list,...res.data.records];
					}						
				}
				if(this.comment_currentPage>=res.data.totalPage){
					this.comment_isLast=1;
				}else{
					this.comment_currentPage+=1;
				}
				
			},
			
			//个人发布动态列表
			async getDT(){
				let res=await this.$request.get('/api/trend/getCustTrendList',{user_id:this.userInfo.user_id});
				this.dongtai=res.data.records;
				
			},
			//订单评估
			async getPJ(){
				let res=await this.$request.get('/api/custJobManage/getCustRecCommentList',{user_id:this.userInfo.user_id});
				this.cgorder=res.data.records;
				let res2=await this.$request.get('/api/job/getCustRecCommentList',{user_id:this.userInfo.user_id});
				this.ajorder=res2.data.records;
			},
			//技能评估
			async zanSkill(id){
				let res=await this.$request.get('/api/resume/skillFavor',{cust_skill_id :id});
				this.getSkillInfo();
			},
			//技能评估
			async getSkillInfo(){
				let res=await this.$request.get('/api/resume/getSkills',{user_id:this.userInfo.user_id});				
				this.skillInfo=res.data.records;
			},
			async changeWorkingStatus(val){
				let res=await this.$request.post('/api/resume/updateStatus',{resume_id:this.userInfo.resume_id,working_status:val});
				this.$message({
					message:res.message,
					type:'success'
				});
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.workingStatus=res.data.working_status.data;
				this.jobTime=res.data.job_time_method.data;
				
			},
			//获取求职信息
			async getJobInfo(){
				let res=await this.$request.get('/api/resume/getResume',{user_id:this.userInfo.user_id});				
				this.jobInfo=res.data;
			},
			//获取个人信息
			async getDatas(){
				let res=await this.$request.get('/api/resume/detail',{user_id:this.user_id });				
				this.userInfo=res.data;
				
				let res1=await this.$request.get('/api/job/getCustContractRadar',{user_id:this.userInfo.user_id});
				this.recInfo=res1.data.recInfo;
				this.sendInfo=res1.data.sendInfo;
				// 接包
				this.drawChart(res1.data);
				// 发包
				this.drawChart2(res1.data);
			},
			drawChart2(data) {
			  // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
			  let myChart = this.$echarts.init(document.getElementById('echarts2'));
			  // 指定图表的配置项和数据
			  let option = {
			    title: {
			      text: '',
				  textStyle:{
					  color:'#FFFFFF',
				  }
				  
			    },
				textStyle:{
					fontFamily:'-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif'
				},
			    // legend: {
			    //   data: ['Allocated Budget', 'Actual Spending']
			    // },
				gradientColor:'#ffffff',
				color:'#F6CB53',
			    radar: {
			      // shape: 'circle',
			      indicator: data.sendIdicator,
				  radius:'50%'
			    },
			   series: [
			     {
			   	name: '',
			   	type: 'radar',
			   	data: [
			   	  {
			   		value: data.sendData.value,
			   		name: '',					
			   	  }
			   	]
			     }
			   ]
			  };
			  // 使用刚指定的配置项和数据显示图表。
			  myChart.setOption(option);
			},
			   drawChart(data) {
			     // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
			     let myChart = this.$echarts.init(document.getElementById('echarts'));
			     // 指定图表的配置项和数据
			     let option = {
			   	title: {
			   	  text: '',
			   	  textStyle:{
			   		  color:'#FFFFFF',
			   	  }
			   	  
			   	},
			   	// legend: {
			   	//   data: ['Allocated Budget', 'Actual Spending']
			   	// },
			   	gradientColor:'#ffffff',
			   	color:'#F6CB53',
			   	radar: {
			   	  // shape: 'circle',
			   	  indicator: data.recIdicator,
				  radius:'50%'
			   	},
			   	series: [
			   	  {
			   		name: '',
			   		type: 'radar',
			   		data: [
			   		  {
			   			value: data.recData.value,
			   			name: ''
			   		  }
			   		]
			   	  }
			   	]
			     };
			     // 使用刚指定的配置项和数据显示图表。
			     myChart.setOption(option);
			   },
		},
		
		
		
	};
</script>

<style lang="less" >
	.liuyan_list{
		.item{margin-top: 20px; padding-bottom: 20px; border-bottom: 1px solid #567BB6;
			&:first-child{margin-top: 0;}
			.date{margin-top: 10px;font-size: 12px;color: #999;}
			
			.touxiang{margin-right: 15px;
				img{width: 60px;height: 60px;border-radius: 5px;}
			}
			.desc{font-size: 12px;margin-top: 10px;}
			.cont{font-size: 14px;margin-top: 15px; line-height: 1.4;
				img{width: 22px;height: 22px;vertical-align: middle;}
			}
		}
	}
	.my_index {
		min-height: 100%;
		background-color: #212121;
		a{
			color: #fff;
		}
		.my_index_top {
			display: flex;
			justify-content: flex-end;
			color: #fff;
	
			.top_user {
				width: 1164px;
				margin-right: 50px;
	
				.top_user_title {
					height: 65px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 18px;
					border-top: 1px solid #567BB6;
	
					.bi {
						width: 20px;
						height: 20px;
					}
				}
	
				.top_user_geng {
					display: flex;
					align-items: center;
					width: 100%;
					margin-bottom: 30px;
	
					.geng_text {
						flex-shrink: 0;
						width: 120px;
						font-size: 12px;
					}
	
					.xian {
						width: 100%;
						height: 1px;
						background-color: #567BB6;
					}
				}
	
				.top_user_one {
					display: flex;
					align-items: flex-start;
					.one_left {
						position: relative;
						// display: flex;
	
						.left_user {
							padding-left: 128px;
							display: flex;
	
							.user_name {
								border-right: 1px solid #567BB6;
	
								.name_title {
									font-size: 40px;
									font-weight: bold;
									margin-bottom: 30px;
									width: 245px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
								
								.name_msg {
									font-size: 14px;
									margin-top: 10px;
									width: 245px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
							}
						}
						.enname{
							position: absolute;
							top: 0;
							left: 0;
							font-weight: bold;
							transform:rotateZ(180deg);
							text-transform: uppercase;
							line-height: 1.2;
							
							font-size: 70px;
							writing-mode: vertical-rl;
						}
						.my_bg {
							position: absolute;
							top: 0;
							left: 0;
							height: 1000px;
							width: 70px;
						}
	
						.left_num {
							width: 366px;
							border-right: 1px solid #567BB6;
							height: 143px;
							padding: 0 5px;
							box-sizing: border-box;
	
							.num_title {
								display: flex;
								align-items: center;
								padding-left: 12px;
								margin-bottom: 20px;
								.el-input__inner {
									
									color: #fff;
									border: none;
								}
								.red .el-input__inner {
									background: #B65664;
									
								}
								.green .el-input__inner {
									background: #50b670;
									
								}
								.yellow .el-input__inner {
									background: #fab269;
									
								}
	
								.video_list {
									width: 250px;
									display: flex;
									justify-content: center;
									align-items: center;
	
									.video1 {
										width: 34px;
										height: 24px;
										margin: 0 12px;
									}
	
									.video2 {
										width: 34px;
										height: 34px;
										margin: 0 12px;
									}
	
									.video3 {
										width: 34px;
										height: 34px;
										margin: 0 12px;
									}
								}
							}
							.num_box{
								display: flex;
								align-items: center;
								justify-content: space-between;
								.box{
									width: 77px;
									height: 77px;
									background: #212121;
									border: 2px solid #567BB6;
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: space-evenly;
									flex-direction: column;
									font-size: 12px;
									text-align: center;
								}
							}
						}
						.left_center_box{
							width: 720px;
							// min-height: 1248px;
							background: #567BB6;
							margin-top: 21px;
							padding-left: 124px;
							padding-bottom: 26px;
							box-sizing: border-box;
							.center_img{
								width: 597px;
								height: 597px;
								object-fit: cover;
							}
							.center_about{
								width: 543px;
								margin-top: 30px;
								padding: 0 35px;
								border-left: 1px solid #FFFFFF;
								border-right: 1px solid #FFFFFF;
								box-sizing: border-box;
								.about_title{
									display: flex;
									align-items: center;
									justify-content: space-between;
									font-size: 15px;
									font-weight: bold;
									margin-bottom: 10px;
									margin-top: 20px;
									.bi{
										width: 20px;
										height: 20px;
									}
								}
								.about_li{
									// height: 65px;
									border-bottom: 1px solid #FFFFFF;
									display: flex;
									align-items: flex-start;
									margin: 15px 0 0;
									padding-bottom: 10px;
									.job{
										width: 31px;
										height: 31px;
										margin-right: 10px;
									}
									.li_name{
										// .msg{
										// 	width: 463px;
										// 	overflow: hidden;
										// 	text-overflow: ellipsis;
										// 	white-space: nowrap;
										// }
										.msg2{margin-top: 10px;font-size: 12px;line-height: 1.3;}
									}
								}
								.about_msg{
									font-size: 14px;
									line-height: 1.4;
								}
							}
						}
					}
				
					.one_right{
						.right_num{
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;
							margin-left: 40px;
							.num_box{
								width: 170px;
								height: 170px;
								background: #212121;
								border: 5px solid #567BB6;
								border-radius: 50%;
								display: flex;
								align-items: center;
								flex-direction: column;
								justify-content: center;
								margin-bottom: 40px;
								.box_top{
									height: 50%;
									display: flex;
									align-items: center;
									flex-direction: column;
									justify-content: center;
									.top_money{
										width: 26px;
										height: 26px;
										margin-bottom: 10px;
									}
									.top_name{
										font-size: 12px;
										color: #fff;
									}
								}
								.box_xian{
									width: 100%;
									height: 3px;
									background-color: #567BB6;
								}
								.box_bottom{
									height: 50%;
									width: 100%; 
									font-size: 30px;
									color: #fff;
									text-align: center;
									display: flex;
									align-items: center;
									justify-content: center;
									padding-bottom: 30px;
									box-sizing: border-box;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
							}
						}
						.right_echarts{
							width: 373px;
							height: 480px;
							background: #212121;
							border-radius: 3px;
							border: 1px solid #567BB6;
							padding: 30px;
							box-sizing: border-box;
							margin-left: 40px;
							margin-bottom: 20px;
							.echarts_one{
								display: flex;
								align-items: center;
								border-bottom: 1px solid #567BB6;
								padding-bottom: 20px;
								.one_title{
									width: 30px;
									padding:0 15px;
									height: 155px;
									display: flex;
									flex-direction: column;
									align-items: flex-start;
									justify-content: center;
									font-size: 30px;
									
									border-right: 1px solid #567BB6;
									line-height: 1.5;
									margin-right: 30px;
								}
								.one_pin{
									width: 202px;
									height: 155px;
									display: flex;
									flex-direction: column;
									justify-content: space-between;
									.pin_xian{
										height: 1px;
										background-color: #567BB6;
										width: 200px;
									}
									.pin_box{
										width: 202px;
										// height: 75px;
										.box_num{
											display: flex;
											align-items: center;
											font-size: 14px;
											margin-bottom: 10px;
											span{
												font-size: 30px;
												margin-left: 15px;
											}
										}
										
										.box_star{
											display: flex;
											align-items: center;
											font-size: 14px;
										}
									}
								}
							}
							.echarts_box{
								margin:20px -25px 0;
								height: 250px;
								
							}
						}
						
					}
					
					
				}
			}
		}
		
		.my_index_two{
			width: 1300px;
			margin: 50px auto;
			color: #fff;
			display: flex;
			justify-content: space-between;
			.two_left{
				width: 616px;
				.left_title{
					height: 40px;
					line-height: 40px;
					font-size: 15px;
					border-bottom: 1px solid #567BB6;
					font-weight: bold;
					margin-bottom: 15px;
				}
				.left_pro{
					display: flex;
					align-items: center;
					width: 616px;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-bottom: 30px;
					.pro_list{
						width: 45%;
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						.list_name{
							width: 202px;
							height: 21px;
							background: #567BB6;
							font-size: 12px;
							padding-left: 5px;
							box-sizing: border-box;
							line-height: 21px;
							margin-right: 10px;
						}
						.list_btn{
							display: flex;
							align-items: center;
							font-size: 12px;
							.btn{
								width: 21px;
								height: 21px;
								margin-right: 5px;
							}
						}
					}
				}
				
				.table_title{
					width: 614px;
					display: flex;
					align-items: center;
					div{width:50%;height: 44px;line-height: 44px;text-align: center;box-sizing: border-box;
						&:nth-child(1){padding-left: 60px;
							background: url(../../assets/img/bg_t01.png) no-repeat right center;
							&.active{background-image: url(../../assets/img/bg_t01_h.png);padding-left: 0;}
						}
						&:nth-child(2){
							background: url(../../assets/img/bg_t02.png) no-repeat left center;padding-right: 60px;
							&.active{background: url(../../assets/img/bg_t02_h.png) right center;padding-right: 0;}
						}
					}
					// margin-bottom: 20px;
					// .active{
					// 	width: 50%;
					// 	height: 50px;
					// 	line-height: 50px;
					// 	text-align: center;
					// 	background-color: #567BB6;
						
						
					// }
					// .title{
					// 	width: 50%;
					// 	height: 50px;
					// 	line-height: 50px;
					// 	text-align: center;
					// 	background-color: #212121;
					// 	box-sizing: border-box;
					// 	border: 1px solid #567BB6;
						
					// }
				}
				.left_table{
					width: 614px;
					min-height: 100px;
					background-color: #567BB6;
					padding-bottom: 30px;
					border-radius: 0 45px 45px 45px;
					padding-top: 20px;
					margin-bottom: 30px;
					&.two{border-radius: 45px 0 45px 45px;}
					.table_header{
						display: flex;
						align-items: center;
						padding: 0 25px;
						margin: 10px 0;
						.header{
							width: 80px;
							text-align: center;
							flex-shrink: 0;
						}
						.header_last{
							text-align: center;
							width: 100%;
						}
					}
					.table_body{
						display: flex;
						align-items: center;
						margin: 10px 25px;
						background-color: #212121;
						min-height: 96px;
						.body{
							width: 80px;
							min-height: 96px;
							text-align: center;
							flex-shrink: 0;
							padding: 10px 0;
							box-sizing: border-box;
							font-size: 12px;
							border-right: 1px solid #567BB6;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							word-break: break-word;
							.body_img{
								width: 65px;
								height: 65px;
								object-fit: cover;
							}
							.el-rate__icon{
								margin-right: 0px;
								font-size: 12px;
							}
						}
						.body_last{
							min-height: 96px;
							width: 100%;
							display: flex;
							text-align: center;
							align-items: center;
							justify-content: center;
							padding: 10px;
							box-sizing: border-box;
							font-size: 14px;
							line-height: 1.2;
							word-break: break-word;
						}
					}
				}
				.table_center{
					padding: 28px 17px;
					background-color: #212121;
					margin: 25px;
					.table_time{
						display: flex;
						align-items: center;
						.time{
							width: 70px;
							font-size: 12px;
						}
						.xian{
							width: 100%;
							height: 1px;
							background-color: #567BB6;
						}
					}
					.table_user{
						display: flex;
						padding:  20px 0;
						border-bottom: 1px solid #567BB6;
						.user_img{
							width: 77px;
							height: 77px;
							margin-right: 15px;
							border-radius: 10px;
						}
						.user_right{
							.right_name{
								font-size: 15px;
								font-weight: bold;
								margin-bottom: 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.right_text{
								font-size: 14px;
								margin-bottom: 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.right_msg{
								font-size: 12px;
								margin-bottom: 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								
							}
						}
					}
					.table_html{
						padding: 15px 0;
						line-height: 1.4;
						font-size: 12px;
					}
				}
			}
		
			.two_right{
				width: 643px;
				.left_title{
					height: 40px;
					line-height: 40px;
					font-size: 15px;
					border-bottom: 1px solid #567BB6;
					font-weight: bold;
					margin-bottom: 15px;
				}
				.home_list{
					width: 100%;
					border-radius: 7px;
					box-sizing: border-box;
					padding: 17px 35px;
					background-color: #567BB6;
					margin-bottom: 17px;
					
					.list_title{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 17px;
						.title_img{
							width: 42px;
							height: 42px;
							border-radius: 2px;
							 margin-right: 10px;
						}
						.list_user{
							
							.user{
								font-size: 14px;
								margin-bottom: 10px;
								font-weight: bold;
							}
							.user_msg{
								font-size: 12px;
							}
						}
						
						.el-button{
							background-color: #fff;
							color: #567BB6;
							border: none;
						}
						.active{
							color: #2D2D2E;
							background-color: #F3F1EA;
							border: 1px solid #567BB6;
						}
					}
					.list_text{
						font-size: 14px;
						line-height: 1.3;
						margin-bottom: 20px;
					}
					.img_list{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.img{
							width: 280px;
							height: 191px;
							margin-bottom: 9px;
						}
					}
					.like_box{
						height: 30px;
						padding: 0 8px;
						display: flex;
						align-items: center;
						border-top: 1px solid #fff;
						font-size: 12px;
						.like_img{
							width: 20px;
							height: 20px;
							margin-right: 6px;
						}
					}
					.btn_box{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 45px;
						border-top: 1px solid #fff;
						border-bottom: 1px solid #fff;
						.btn_left{
							display: flex;
							align-items: center;
							margin-left: 85px;
							.left_menu{
								display: flex;
								align-items: center;
								margin-right: 70px;
								font-size: 14px;
								font-weight: bold;
								position: relative;
								&:hover .sharemenu{display: block;}
								.btn1{
									width: 24px;
									height: 24px;
									margin-right: 8px;
								}
								.btn2{
									width: 21px;
									height: 21px;
									margin-right: 8px;
								}
								.btn3{
									width: 19px;
									height: 22px;
									margin-right: 8px;
								}
							}
						}
						.right_btn{
							width: 27px;
							height: 27px;
						}
					}
					.input_box{
						width: 100%;
						height: 38px;
						background: #FFFFFF;
						border-radius: 9px;
						margin: 15px 0;
						box-sizing: border-box;
						font-size: 14px;
					}
					.input_add{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 42px;
						border-bottom: 1px solid #fff;
						.biaoqin{
							width: 25px;
							height: 25px;
						}
						.el-button{
							background-color: #fff;
							color: #567BB6;
							border: none;
						}
					}
					.pin_list{
						width: 100%;
						.list_box{
							width: 100%;
							margin: 15px 0;
							display: flex;
							.box_img{
								width: 34px;
								height: 34px;
								margin-right: 15px;
								border-radius: 2px;
								flex-shrink: 0;
							}
							.box_right{
								width: 100%;
								.right_user{
									display: flex;
									justify-content: space-between;
									align-items: center;
									.user_name{
										.name{
											
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: normal;
											font-size: 12px;
											margin-bottom: 10px;
											font-weight: bold;
										}
										.name_time{
											font-size: 10px;
										}
									}
									.user_btn{
										display: flex;
										align-items: center;
										.btn1{
											width: 24px;
											height: 24px;
											margin-left: 20px;
										}
										.btn2{
											width: 21px;
											height: 21px;
											margin-left: 20px;
										}
										.btn3{
											width: 24px;
											height: 24px;
											margin-left: 20px;
										}
									}
								}
								.right_msg{
									font-size: 12px;
									margin: 10px 0;
									line-height: 1.3;
									img{width: 22px;height: 22px;vertical-align: middle;}
								}
								.right_more{
									font-size: 10px;
									display: flex;
									align-items: center;
									border-left: 1px solid #fff;
									.more_user{
										color: #567BB6;
										margin: 0 5px;
									}
								}
							}
						}
					}
					.pin_more{
						font-size: 14px;
						text-align: center;
						padding-top: 15px;
						border-top: 1px solid #fff;
						.el-button{
							background: #567BB6;
							border: none;
							font-size: 14px;
							color: #fff;
							font-weight: bold;
						}
					}
				}
				.last_box{
					width: 643px;
					min-height: 294px;
					border: 1px solid #567BB6;
					padding: 30px 20px;
					display: flex;
					box-sizing: border-box;
					// align-items: center;
					flex-wrap: wrap;
					.box{
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 170px;
						margin: 12px;
						.box_img{
							width: 171px;
							height: 171px;
							border-radius: 10px;
							border: 1px solid #567BB6;
							margin-bottom: 10px;
						}
						.box_name{
							font-size: 15px;
							margin-bottom: 15px;
						}
						.box_msg{
							font-size: 15px;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
		
	}
</style>
